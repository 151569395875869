import { environment } from "../../environments/environment";
import { noOpFn } from "../util/noOpFunction";

type LogObj = Pick<
  typeof console,
  "log" | "trace" | "debug" | "info" | "warn" | "error" | "table" | "group" | "groupEnd"
>;

export type LoggerOptions = {
  forcedLogging?: boolean;
};

const blackHoleLogger: LogObj = {
  log: () => noOpFn,
  trace: () => noOpFn,
  debug: () => noOpFn,
  info: () => noOpFn,
  warn: () => noOpFn,
  error: () => noOpFn,
  table: () => noOpFn,
  group: () => noOpFn,
  groupEnd: () => noOpFn,
};

const loggerInstance: LogObj = environment.deployed ? blackHoleLogger : console;

/**
 * Usage:
 *  ```
 * import useLogger from "../hooks/useLogger";
 * const MyComponent = () => {
 *    const log = useLogger();
 *    log.info("Such Water Wow!");
 *  }
 * ```
 */
const useLogger = ({ forcedLogging }: LoggerOptions = { forcedLogging: false }): LogObj => {
  if (forcedLogging) {
    return console;
  }

  return loggerInstance;
};

export default useLogger;
