import React, { useEffect, useState } from "react";

import type { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useHistory } from "react-router-dom";

import { initializeAppInsights, isAppInsightsInitialized } from "../../appInsights";
import { useEnvironment } from "../context/EnvContext";

const TelemetryProvider: React.FC = ({ children }) => {
  const env = useEnvironment();
  const history = useHistory();
  const [reactPlugin, setReactPlugin] = useState<ReactPlugin | undefined>(undefined);

  useEffect(() => {
    if (env.AppInsightsConnectionString && !isAppInsightsInitialized()) {
      // @ts-expect-error - history is a strange type
      const [, reactPlugin] = initializeAppInsights(env.AppInsightsConnectionString, history);
      setReactPlugin(reactPlugin);
    }
    // Specifically do *not* include history here as we'll assume it exists at this point. It changes constantly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env.AppInsightsConnectionString]);

  if (reactPlugin) {
    return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
  } else {
    return <>{children}</>;
  }
};

export default TelemetryProvider;
