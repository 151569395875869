import { useCallback, useState } from "react";

import useSWR from "swr";

import { useOnlineStatus } from "../OnlineStatusContext";

export type EnvJson = {
  deployment: "dev" | "prod" | "local" | string;
  release: "pending" | string;
  ga_id?: "unknown" | string;
  ai?: string;
};

type FetchState = "initializing" | "staggered" | "continuing";

const FETCH_INTERVAL_MS = 30_000;

/**
 * (Small shim here used by swr - this should be moved to a util if we end up using SWR anywhere else)
 */
const fetcher = (url: string) => fetch(url).then((r) => r.json());

/**
 * Returns a random interval value between FETCH_INTERVAL_MS and 2 * FETCH_INTERVAL_MS
 */
const randomFirstInterval = () => FETCH_INTERVAL_MS + Math.floor(Math.random() * FETCH_INTERVAL_MS);

/**
 * A hook that fetches (and re-fetches) /env.json on a regular basis and returns its contents.
 *
 * This hook leverages suspense mode, so that a parent suspense block will... block the UI until
 * the first data is fetched.  The return value from this hook is guaranteed to be defined.
 *
 * The rules for the refresh intervals are as follows:
 *   - When the hook is first loaded, it uses a tiny refresh interval (1ms) to ensure that env.json is requested immediately.
 *   - The second interval is a random value, to avoid refresh "storms" if many browsers come back online at the same time,
 *     so that we can spread out the requests.
 *   - All subsequent requests use the constant value (FETCH_INTERVAL_MS) defined in this module.
 */
export const useFetchEnvJson = () => {
  /**
   * fetchState is a tiny state machine.  It only traverses forward:  initializing -> staggered -> continuing.
   */
  const [fetchState, setFetchState] = useState<FetchState>("initializing");

  const { markConnectionOffline } = useOnlineStatus();

  /**
   * Returns an appropriate refresh interval for the current fetchState and, as a side effect,
   * pushes the fetchState forward.
   */
  const refreshInterval = useCallback(() => {
    switch (fetchState) {
      case "initializing":
        setFetchState("staggered");
        return 1;
      case "staggered":
        setFetchState("continuing");
        return randomFirstInterval();
      case "continuing":
        return FETCH_INTERVAL_MS;
    }
  }, [fetchState]);

  const { data } = useSWR<EnvJson>("/env.json", fetcher, {
    suspense: true,
    refreshInterval: refreshInterval,
    onError: (error: Error) => {
      markConnectionOffline(`fetchEnv failed: ${error.message}`);
    },
  });

  if (!data) {
    // suspense mode in swr guarantees data is set
    throw new Error("Unexpectedly did not have environment data available");
  }

  return data;
};
