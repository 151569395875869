import { useCallback, useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import type { Event, EventProcessor } from "@sentry/types";

import type { EnvJson } from "./useFetchEnvJson";

export const useConfigureSentryEvents = (env: EnvJson) => {
  const [processorAdded, setProcessorAdded] = useState(false);

  const eventProcessor = useCallback<EventProcessor>(
    (event: Event) => {
      event.environment = env.deployment;
      event.release = `frontend-main@${env.release}`;
      return event;
    },
    [env.deployment, env.release],
  );

  useEffect(() => {
    const scope = Sentry.getCurrentHub().getScope();

    if (!scope || processorAdded) {
      return;
    }

    scope.addEventProcessor(eventProcessor);
    setProcessorAdded(true);
  }, [env.release, eventProcessor, processorAdded]);
};
