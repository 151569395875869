import type { CSSProperties } from "react";
import React from "react";

import logoImg from "../assets/logo.svg";

import { LoadingAnimation } from "./loadingAnimation";

/**
 * This loader very specifically should *not* incorporate material-ui or other
 * larger libraries, as it is used primarily in Suspense fallbacks to allow for
 * a better experience while lazy-loading larger libraries and components.
 * (Inline styles aren't very nice, but in use here to avoid importing mui-styles)
 */

const styles: Record<string, CSSProperties> = {
  loaderMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",

    width: "100vw",
    height: "100vh",

    backgroundColor: "#1c2b35",
  },

  loaderContent: {
    margin: "auto",
    color: "white",
  },

  loaderContentH1: {
    fontSize: 20,
    display: "flex",
    margin: "0 10px",
    flexShrink: 1,
  },

  loaderMainLogo: {
    width: 300,
    height: 80,
  },

  loaderText: {
    display: "flex",
    flexDirection: "row",
    // width: "100%",
    height: 30,
    marginTop: 10,

    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
  },

  loaderTextSpacer: {
    flexGrow: 1,
  },
};

export const FullScreenLoader: React.FC = () => {
  return (
    <main style={styles.loaderMain}>
      <div style={styles.loaderContent}>
        <div style={styles.loaderMainLogo}>
          <img src={logoImg} alt="Waterly Logo" />
        </div>
        <div style={styles.loaderText}>
          <div style={styles.loaderTextSpacer} />
          <h1 style={styles.loaderContentH1}>Loading...</h1>
          <LoadingAnimation />
        </div>
      </div>
    </main>
  );
};
