import type { FC } from "react";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import { useEvent } from "react-use";

import useLogger from "../hooks/useLogger";
import { noOpFn } from "../util/noOpFunction";

export enum OnlineStatus {
  online = "online",
  offline = "offline",
}

type OnlineStatusContextValue = {
  status: OnlineStatus;
  markConnectionOnline: (reason: string) => void;
  markConnectionOffline: (reason: string) => void;
};

const onlineStatusFromBrowser = () => (window.navigator.onLine ? OnlineStatus.online : OnlineStatus.offline);

const defaultValue: OnlineStatusContextValue = {
  status: onlineStatusFromBrowser(),
  markConnectionOnline: noOpFn,
  markConnectionOffline: noOpFn,
};

const OnlineStatusContext = createContext<OnlineStatusContextValue>(defaultValue);

const OnlineStatusContextProvider: FC = ({ children }) => {
  const log = useLogger();
  const [status, setStatus] = useState<OnlineStatus>(() => onlineStatusFromBrowser());

  const updateConnectionStatus = useCallback(
    (newStatus: OnlineStatus, reason: string) => {
      log.info(`Received ${newStatus} event: ${reason}`);
      setStatus(newStatus);
    },
    [log],
  );

  const markConnectionOnline = useCallback(
    (reason: string) => updateConnectionStatus(OnlineStatus.online, reason),
    [updateConnectionStatus],
  );

  const markConnectionOffline = useCallback(
    (reason: string) => updateConnectionStatus(OnlineStatus.offline, reason),
    [updateConnectionStatus],
  );

  const onNavigatorEvent = useCallback(
    (evt: Event) => {
      updateConnectionStatus(
        evt.type === "online" ? OnlineStatus.online : OnlineStatus.offline,
        `browser updated online status to: ${evt.type}`,
      );
    },
    [updateConnectionStatus],
  );

  useEvent("online", onNavigatorEvent);
  useEvent("offline", onNavigatorEvent);

  const value = useMemo<OnlineStatusContextValue>(
    () => ({
      status,
      markConnectionOnline,
      markConnectionOffline,
    }),
    [markConnectionOffline, markConnectionOnline, status],
  );

  return <OnlineStatusContext.Provider value={value}>{children}</OnlineStatusContext.Provider>;
};

export default OnlineStatusContextProvider;

export const useOnlineStatus = () => {
  const ctx = useContext(OnlineStatusContext);
  if (!ctx) {
    throw new Error("useOnlineStatus() may only be used from a child of OnlineStatusContext.");
  }
  return ctx;
};
