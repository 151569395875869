import { useEffect, useState } from "react";

import { usePrevious } from "react-use";

import type { EnvJson } from "./useFetchEnvJson";

export const useIsNewReleaseAvailableDetector = (env?: EnvJson) => {
  const [isNewRelease, setIsNewRelease] = useState<boolean>(false);
  const previousActiveRelease = usePrevious(env?.release);

  useEffect(() => {
    if (isNewRelease || !env || previousActiveRelease === undefined) {
      return;
    }

    if (env.release !== previousActiveRelease) {
      setIsNewRelease(true);
    }
  }, [env, isNewRelease, previousActiveRelease]);

  return isNewRelease;
};
