import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import type { ITelemetryPlugin } from "@microsoft/applicationinsights-web";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import type { History } from "history";

let appInsights: ApplicationInsights | undefined;

export const reactPlugin = new ReactPlugin();

export const initializeAppInsights = (
  connectionString: string,
  history: History,
): [appInsights: ApplicationInsights | undefined, reactPlugin: ReactPlugin] => {
  const ai = new ApplicationInsights({
    config: {
      connectionString,
      // Ugh. Some issues with typing conflicts currently. Can be removed once we've fully consumed
      // the result from https://github.com/microsoft/applicationinsights-react-js/issues/32
      extensions: [reactPlugin as unknown as ITelemetryPlugin],
      isBeaconApiDisabled: true,
      disableTelemetry: false,
      disableCorrelationHeaders: false,
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: false, // reactPlugin catches this
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxErrorStatusText: true,
      enableAjaxPerfTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      correlationHeaderDomains: ["app.waterlyapp.com", "app.dev.waterlyapp.com"],
      samplingPercentage: 10,
      extensionConfig: {
        [reactPlugin.identifier]: {
          history,
        },
      },
    },
  });

  ai.addTelemetryInitializer((envelope) => {
    envelope.tags = envelope.tags ?? [];
    envelope.tags["ai.cloud.role"] = "WaterlyFrontend";
  });

  ai.loadAppInsights();

  ai.trackPageView();
  appInsights = ai;

  return [ai, reactPlugin];
};

export const isAppInsightsInitialized = () => !!appInsights;

export const getAppInsights = () => {
  if (!appInsights) {
    throw new Error("Application Insights has not yet been initialized");
  }

  return appInsights;
};
