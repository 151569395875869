import { useTimeout } from "react-use";

export type LoadingAnimationProps = {
  immediate?: boolean;
  width?: number;
};

const DELAY_MS = 300;

export const LoadingAnimation = ({ immediate = true, width = 24 }: LoadingAnimationProps) => {
  const [isReady] = useTimeout(immediate ? 0 : DELAY_MS);

  if (!isReady()) {
    return null;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      preserveAspectRatio="xMidYMid"
      width={width}
      fill="#3c5c71"
    >
      <rect x="1.5" y="1.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0s"
          calcMode="discrete"
        />
      </rect>
      <rect x="7.5" y="1.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0.125s"
          calcMode="discrete"
        />
      </rect>
      <rect x="13.5" y="1.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0.25s"
          calcMode="discrete"
        />
      </rect>
      <rect x="13.5" y="7.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0.375s"
          calcMode="discrete"
        />
      </rect>
      <rect x="13.5" y="13.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0.5s"
          calcMode="discrete"
        />
      </rect>
      <rect x="7.5" y="13.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0.625s"
          calcMode="discrete"
        />
      </rect>
      <rect x="1.5" y="13.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0.75s"
          calcMode="discrete"
        />
      </rect>
      <rect x="1.5" y="7.5" width="5" height="5">
        <animate
          attributeName="fill"
          values="#43c1c3;#3c5c71"
          keyTimes="0;0.125"
          dur="1s"
          repeatCount="indefinite"
          begin="0.875s"
          calcMode="discrete"
        />
      </rect>
    </svg>
  );
};
