import React, { Suspense } from "react";

import { shouldPolyfill } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import * as Sentry from "@sentry/react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import { isLocal } from "./app/config/featureFlags";
import { EnvContextProvider } from "./app/context/EnvContext";
import OnlineStatusContextProvider from "./app/context/OnlineStatusContext";
import TelemetryProvider from "./app/providers/TelemetryProvider";
import { FullScreenLoader } from "./app/ui/loaders/fullScreenLoader";
import { initFeatureFlagOverrides } from "./app/util/featureFlagsUtilities";
import initMuiLicense from "./app/util/initMuiLicense";
import { lazyWithRetry } from "./app/util/lazyWithRetry";
import { environment } from "./environments/environment";

import "./initI18next";

Sentry.init({
  dsn: isLocal ? "" : "https://8c04c6de44a9418a8b57700e85210311@o1083388.ingest.sentry.io/6093050",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: "unknown",
  release: "unknown",
  enabled: environment.deployed,
  // capture replays on 2% of sessions - let's see how this works out
  replaysSessionSampleRate: 0.02,
  // capture replay on all errors
  replaysOnErrorSampleRate: 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

(async () => {
  initFeatureFlagOverrides();
})();

const App = lazyWithRetry(() => import("./app/app"));

initMuiLicense();

const renderApp = () =>
  render(
    <React.StrictMode>
      <Suspense fallback={<FullScreenLoader />}>
        <BrowserRouter>
          <CompatRouter>
            <OnlineStatusContextProvider>
              <EnvContextProvider>
                <TelemetryProvider>
                  <App />
                </TelemetryProvider>
              </EnvContextProvider>
            </OnlineStatusContextProvider>
          </CompatRouter>
        </BrowserRouter>
      </Suspense>
    </React.StrictMode>,
    document.getElementById("root"),
  );

async function start() {
  // Check if platform already supports Intl.getCanonicalLocales
  // https://caniuse.com/mdn-javascript_builtins_intl_supportedvaluesof
  // Global support at the time of writing 89.63%
  if (shouldPolyfill()) {
    await import("@formatjs/intl-getcanonicallocales/polyfill");
  }
  renderApp();
}

start();
