import type { ComponentClass, ComponentType, LazyExoticComponent } from "react";
import { lazy } from "react";

// Inspiration from: https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0

// Isolating type coercion
const nullReturningFnBuilder = <T>() => (() => null) as unknown as T;

const getPageHasBeenForceRefreshed = (): boolean => {
  const storageValue = window.localStorage.getItem("page-has-been-force-refreshed");
  return storageValue === "true";
};

const setPageHasBeenForceRefreshed = (value: boolean) => {
  window.localStorage.setItem("page-has-been-force-refreshed", value.toString());
};

export const lazyWithRetry = <T extends ComponentType<unknown> | ComponentClass<object, unknown>>(
  componentImport: () => Promise<{ default: T }>,
): LazyExoticComponent<T> =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = getPageHasBeenForceRefreshed();

    try {
      const component = await componentImport();

      setPageHasBeenForceRefreshed(false);

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setPageHasBeenForceRefreshed(true);
        window.location.reload();

        // This is a bogus promise - we're reloading, so this won't be in play
        return new Promise((resolve) => {
          resolve({
            default: nullReturningFnBuilder<T>(),
          });
        });
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
