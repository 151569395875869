export const isDevelopment = window.location.hostname === "app.dev.waterlyapp.com";
export const isLocal = window.location.hostname === "localhost";

const featureFlags = {
  numberInputForIOS: false,
  metricMeasurementModal: false, //Added: 03/16/23 Enables opening the metrics measurements modal.
  showSampleManagement: false,
  enableAuditEventsSubscription: false,
  enableLocalization: false,
  enableScheduleManagement: false,
  enableSampleManagement: false,
  detectionReportLimit: false,
  showScheduleManagementArchive: false,
  showScheduleManagementClone: false,
  enableScheduleManagementRecurrence: false,
};

export type FeatureFlags = typeof featureFlags;
export type FeatureFlagsEnum = keyof FeatureFlags;

export const featureFlagBySystemID: Partial<{ [key in FeatureFlagsEnum]: number[] }> = {
  enableScheduleManagement: [
    10, // Lake Zebra, IL
    26, // West Dundee, IL
    899, // Arlington Heights IL
    1040, //	CRWA - Wild Wings WW
    1041, //	CRWA - Wild Wings
    1109, //	CRWA - NBRID WW
    1108, //	CRWA - LSA South Shore WW
    315, // Blue Mountain, Valley Marina WTP
    224, // MO Erika WW
    655 /** Start PA68 **/,
    656,
    691,
    692,
    693,
    652,
    653,
    654,
    668,
    672,
    669,
    671,
    690,
    634,
    693 /** End PA68 **/,
    768, // IL 65 - LAB
    800, // PA 62 - Scranton LAB
    233, // IL 55 - Pekin
    247, // CA40 - Ambler Park
    281, // CA40 - Carmel Area WW
    243, // CA40 - Chualar
    244, // CA40 - Garrapata
    249, // CA40 - Hidden Hills
    274, // CA40 - Indian Springs WW
    275, // CA40 - Las Palmas WW
    246, // CA40 - Monterey
    276, // CA40 - Oak Hills WW
    277, // CA40 - Pasadera WW
    245, // CA40 - Ralph Lane
    278, // CA40 - Spreckels WW
    248, // CA40 - Toro
    279, // CA40 - Village Green WW
    280, // CA40 - White Oaks WW
    /** Start - Singh Operations **/
    954, // SOS - SAG
    920, // SOS - Calvert Gateway
    910, // SOS - Calvert Gateway WW
    1021, // SOS - Boones Estates WW
    1020, // SOS - Lyons Creek WW
    1022, // SOS - Maryland Manor WW
    1014, // SOS - SAG WW
    1015, // SOS - Boones Estates
    1018, // SOS - Idlewood
    1013, // SOS - Lyons Creek
    1016, // SOS - Maryland Manor
    1017, // SOS - Patuxent MHP
    1023, // SOS - Patuxent WW
    /** End - Singh Operations **/
    1115, // Golden Hills CSD
    1165, // Golden Hills Lands
    /** Start - Edinburgh, IN **/
    75, // 💧 Edinburgh, IN
    1036, // 💩 Edinburgh, IN
    1161, // 📝Supt Log
    1162, // 🏋️‍♂️ Edinburgh, IN
    /** End - Edinburgh, IN **/
  ],
};

export default featureFlags;
