import React, { createContext, useContext, useMemo, useState } from "react";

import EnvironmentBannerWrapper from "../ui/global/EnvironmentBannerWrapper";
import { noOpFn } from "../util/noOpFunction";

import { useConfigureSentryEvents } from "./internal/useConfigureSentryEvents";
import type { EnvJson } from "./internal/useFetchEnvJson";
import { useFetchEnvJson } from "./internal/useFetchEnvJson";
import { useIsNewReleaseAvailableDetector } from "./internal/useIsNewReleaseAvailableDetector";

export type EnvironmentDetails = {
  BuildId: string;
  CommitSHA: string;
  Deployment: EnvJson["deployment"] | "pending";
  Release: string;
  AnalyticsId: "unknown" | string;
  AppInsightsConnectionString?: string;
};

type ContextValue = {
  env: EnvironmentDetails;
  setEnv: (newEnv: EnvironmentDetails) => void;
  isNewReleaseAvailable: boolean;
};

export const defaultDeployment = "pending";
export const defaultRelease = "pending";

const initialEnvironmentDetails: EnvironmentDetails = {
  BuildId: import.meta.env.NX_BUILD_ID ?? "unknown",
  CommitSHA: import.meta.env.NX_BUILD_SHA ?? "unknown",
  Deployment: defaultDeployment,
  Release: defaultRelease,
  AnalyticsId: "unknown",
};

const initialContextValue: ContextValue = {
  env: initialEnvironmentDetails,
  setEnv: noOpFn,
  isNewReleaseAvailable: false,
};

const EnvContext = createContext(initialContextValue);

export const EnvContextProvider: React.FC = ({ children }) => {
  const [env, setEnv] = useState(() => initialEnvironmentDetails);
  const envJson = useFetchEnvJson();
  const isNewReleaseAvailable = useIsNewReleaseAvailableDetector(envJson);
  useConfigureSentryEvents(envJson);

  const providerValue = useMemo<ContextValue>(() => {
    return {
      env: {
        ...env,
        Deployment: envJson.deployment,
        Release: `frontend-main@${envJson.release}`,
        AnalyticsId: envJson.ga_id ?? "unknown",
        AppInsightsConnectionString: envJson.ai,
      },
      setEnv,
      isNewReleaseAvailable,
    };
  }, [env, envJson.ai, envJson.deployment, envJson.ga_id, envJson.release, isNewReleaseAvailable]);

  return (
    <EnvContext.Provider value={providerValue}>
      <EnvironmentBannerWrapper>{children}</EnvironmentBannerWrapper>
    </EnvContext.Provider>
  );
};

const useContextInternal = (): ContextValue => {
  const ctx = useContext(EnvContext);
  if (!ctx) {
    throw new Error("useEnvironment may only be used in a descendant of EnvContextProvider.");
  }

  return ctx;
};

export const useEnvironment = () => useContextInternal().env;

export const useIsProductionDeployment = () => {
  const env = useContextInternal().env;
  return useMemo(() => ["pending", "prod"].includes(env.Deployment), [env]);
};

export const useIsNewReleaseAvailable = () => {
  return useContextInternal().isNewReleaseAvailable;
};
