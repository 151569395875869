import { t } from "i18next";
import { useLocalStorage } from "react-use";

import type { DropdownOption } from "../ui/shared/AutoComplete/utils";

type DefaultTypes = {
  editMode: boolean;
  showHiddenAndArchived: boolean;
  bannerDismissedTime: string;
  overviewStateFilter: DropdownOption;
  overviewFavoritesFilter: boolean;
};

const defaults: DefaultTypes = {
  editMode: false,
  showHiddenAndArchived: false,
  bannerDismissedTime: "",
  overviewStateFilter: { id: "ALL_STATES", displayName: t("common:all_states") },
  overviewFavoritesFilter: false,
};

export type StorageKeys = keyof typeof defaults;

export const useLocalStorageClean = <K extends StorageKeys>(key: K, defaultVal?: DefaultTypes[K]) => {
  const [value, setValue] = useLocalStorage(key, defaultVal ?? defaults[key]);

  if (value === undefined) {
    return [defaults[key], setValue] as const;
  }

  return [value, setValue] as const;
};
